import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ROLES } from "./utils/data/Constants.js";

import { EmptyCardLayout, LayoutNoFooter, LayoutWithFooter } from "./layouts/Layout.js";
import SidebarLayout from "./layouts/SidebarLayout.js";
import RootLayout from "layouts/RootLayout.js";
import SimpleLayout from "layouts/SimpleLayout.js";

import RequireAuth from "./utils/RequireAuth";
import PersistLogin from "./utils/PersistLogin";
import Loader from "components/Utils/loader";
import LevelUpModalWrapper from "components/Progress/LevelUpModalWrapper";
import { PlatformProvider } from "utils/PlatformProvider.js";
import useAuth from "utils/hooks/useAuth.js";

const Landing = React.lazy(() => import("./views/LandingPage/Landing.js"));
const Post = React.lazy(() => import("./views/Feed/Post.js"));
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard.js"));
const Imprint = React.lazy(() => import("./views/Secondary/Imprint.js"));
const Rules = React.lazy(() => import("./views/Secondary/Rules.js"));
const Cookies = React.lazy(() => import("./views/Secondary/Cookies.js"));
const Terms = React.lazy(() => import("./views/Secondary/Terms.js"));
const Privacy = React.lazy(() => import("./views/Secondary/Privacy.js"));
const PrivacyEN = React.lazy(() => import("./views/Secondary/Privacy-en.js"));
const QuizEditorNew = React.lazy(() => import("./views/QuizEditorNew/editor"));
const FotgotPassword = React.lazy(() => import("./views/auth/ForgotPassword.js"));
const ResetPassword = React.lazy(() => import("./views/auth/ResetPassword.js"));
const Contact = React.lazy(() => import("./views/Secondary/Contact.js"));
const ErrorPage = React.lazy(() => import("./views/Secondary/404.js"));
const Admin = React.lazy(() => import("./layouts/Admin.js"));
const Profile = React.lazy(() => import("./views/Profile/Profile.js"));
const SettingsLayout = React.lazy(() => import("./views/Settings/SettingsLayout.js"));
const Leaderboard = React.lazy(() => import("./views/Leaderboard/Leaderboard.js"));
const Notifications = React.lazy(() => import("./views/Notifications/Notifications.js"));
const Messages = React.lazy(() => import("./views/Messages/Messages.js"));
const SearchQuizzes = React.lazy(() => import("./views/Search/SearchQuizzes.js"));
const QuizOverview = React.lazy(() => import("./views/Quizoverview/MyQuizzes.js"));
const QuizPage = React.lazy(() => import("./views/Quiz/QuizPage.js"));
const FollowerScreen = React.lazy(() => import("./views/Profile/FollowerScreen.js"));
const SparkPremium = React.lazy(() => import("./views/Spark/SparkPremium.js"));
const Challenge = React.lazy(() => import("./views/Challenge/Challenge.js"));
const Challenges = React.lazy(() => import("./views/Challenge/Challenges.js"));
const Tags = React.lazy(() => import("./views/Tags/Tags.js"));
const ConfirmAccount = React.lazy(() => import("./views/auth/ConfirmAccount.js"));
const ConfirmInfo = React.lazy(() => import("./views/auth/ConfirmInfo.js"));
const Login = React.lazy(() => import("./views/auth/Login.js"));
const Register = React.lazy(() => import("./views/auth/Register.js"));
const QuizExplorer = React.lazy(() => import("./views/QuizExplorer/QuizExplorer.js"));
const List = React.lazy(() => import("./views/List/List.js"));
const Lists = React.lazy(() => import("./views/List/Lists.js"));
const RewardCenter = React.lazy(() => import("./views/Badges/RewardCenter.js"));
const RewardCategory = React.lazy(() => import("./views/Badges/RewardCategory.js"));

const Updates = React.lazy(() => import("./views/Updates/Updates.js"));
const Onboarding = React.lazy(() => import("./views/Dashboard/WelcomeUser/Onboarding.js"));
const Feed = React.lazy(() => import("./views/Feed/Feed.js"));
const UpgradeSuccess = React.lazy(() => import("./views/Spark/UpgradeSuccess.js"));


const StoryList = React.lazy(() => import("./legendini/views/story/StoryList.js"));
const StoryEditor = React.lazy(() => import("./legendini/views/story/editor/StoryEditor.js"));
const ChapterEditor = React.lazy(() => import("./legendini/views/story/editor/ChapterEditor.js"));
const StoryOverview = React.lazy(() => import("./legendini/views/story/view/StoryOverview.js"));
const ReadChapter = React.lazy(() => import("./legendini/views/story/view/ReadChapter.js"));
const MyStories = React.lazy(() => import("./legendini/views/myStories/MyStories.js"));
const StoriesExplorer = React.lazy(() => import("./legendini/views/story/StoriesExplorer.js"));
const StoryTags = React.lazy(() => import("./legendini/views/tags/StoryTags.js"));
const ExploreLegendini = React.lazy(() => import("./legendini/views/explore/explore.js"));
const SearchStories = React.lazy(() => import("./views/Search/SearchStories.js"));
const Callback = React.lazy(() => import("./views/auth/Callback.js"));

function App() {
  const { auth } = useAuth();
  const isAuthenticated = auth?.user;
  return (
    <Suspense fallback={<Loader />}>
      <PlatformProvider>
        <LevelUpModalWrapper />
        <Routes>
          <Route element={<PersistLogin />}>          
            {!isAuthenticated && (
              <Route path="/" element={<Landing />} />
            )}
            <Route path="/onboarding" exact element={<Onboarding />} />

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="admin/*" element={<Admin />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="editor/:quizId/*" element={<QuizEditorNew />} />
              <Route path="storyeditor/:storyId/chapter/:chapterId" element={<ChapterEditor />} />
              <Route path="storyeditor/:storyId/*" element={<StoryEditor />} />
            </Route>
            <Route element={<RootLayout />}>

              <Route element={<LayoutNoFooter />}>
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                  <Route path="messages/" element={<Messages />} />
                  <Route path="messages/:conversationId" element={<Messages />} />
                  <Route path="challenge/:challengeId" element={<Challenge />} />
                  <Route path="challenges" element={<Challenges />} />
                  <Route path="overview" exact element={<QuizOverview />} />
                  <Route path="settings/*" element={<SettingsLayout />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="feed" exact element={<Feed />} />
                  <Route path="mystories" element={<MyStories />} />
                </Route>
                <Route path="/rewards" element={<RewardCenter />} />
                <Route path="/rewards/:category" element={<RewardCategory />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/leaderboard/:category" element={<Leaderboard />} />
                <Route path="/post/:postId" exact element={<Post />} />
                <Route path="user/:username/*" element={<Profile />} />
              </Route>
              <Route path="embed/quiz/:quizId" element={<QuizPage />} />

              <Route element={<SidebarLayout />}>
                <Route path="/popular" exact element={<QuizExplorer />} />
                <Route path="/latest" exact element={<QuizExplorer />} />
                <Route path="/featured" exact element={<QuizExplorer />} />
                <Route path="/trending" exact element={<QuizExplorer />} />
                <Route path="/random" exact element={<QuizExplorer />} />
                <Route path="/category/:category" element={<QuizExplorer />} />
                <Route path="/category/:category/:filter" element={<QuizExplorer />} />
                <Route path="/tags/:tag" exact element={<Tags />} />
                <Route path="/search" element={<SearchQuizzes />} />
                <Route path="/stories/search" element={<SearchStories />} />
              </Route>

              <Route element={<LayoutWithFooter />}>
                <Route path="legendini" element={<ExploreLegendini />} />
                <Route path="quiz/:quizId" element={<QuizPage />} />
                <Route path="updates" element={<Updates />} />
                <Route path="story/:storyId" element={<StoryOverview />} />
                <Route path="story/:storyId/:chapterId" element={<ReadChapter />} />
                <Route path="/storytags/:tag" exact element={<StoryTags />} />
                <Route path="/stories/latest" element={<StoriesExplorer />} />
                <Route path="/stories/popular" element={<StoriesExplorer />} />
                <Route path="/stories/:category" element={<StoriesExplorer />} />
                <Route path="/stories/:category/:filter" element={<StoriesExplorer />} />
                <Route path="user/:username/followers" element={<FollowerScreen />} />
                <Route path="user/:username/following" element={<FollowerScreen />} />
                <Route path="/contact" exact element={<Contact />} />
                <Route path="/404" exact element={<ErrorPage />} />
                <Route path="/spark" exact element={<SparkPremium />} />
                <Route path="/lists" exact element={<Lists />} />
                <Route path="/lists/:listId" exact element={<List />} />
                <Route path="/stories/lists/:listId" exact element={<StoryList />} />
                <Route path="/explore" exact element={<Dashboard />} />
                {isAuthenticated && (
                  <Route path="/" element={<Dashboard />} />
                )}
                <Route path="*" to="/" element={<ErrorPage />} />
              </Route>
            </Route>

            <Route element={<EmptyCardLayout />}>
              <Route
                path="/reset-password/:userid/:resetid"
                element={<ResetPassword />}
              />
              <Route path="/forgot-password" exact element={<FotgotPassword />} />
              <Route path="/confirm-email/:userid" element={<ConfirmAccount />} />
              <Route path="/confirm-info" element={<ConfirmInfo />} />
              <Route path="/register" exact element={<Register />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/upgrade-success" exact element={<UpgradeSuccess />} />
              <Route path="/auth/callback" exact element={<Callback />} />
            </Route>

            <Route element={<SimpleLayout />}>
              <Route path="/terms" exact element={<Terms />} />
              <Route path="/cookies" exact element={<Cookies />} />
              <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/privacy-en" exact element={<PrivacyEN />} />
              <Route path="/imprint" exact element={<Imprint />} />
              <Route path="/rules" exact element={<Rules />} />
            </Route>
          </Route>
        </Routes>
      </PlatformProvider>
    </Suspense>
  );
}

export default App;
